import {
  Box,
  css,
  styled,
  Typography,
  Button as MuiButton,
  ButtonProps,
  Fab,
} from '@mui/material';
import {
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  HTMLProps,
  ReactNode,
  Ref,
} from 'react';

export const Flex = styled(Box)`
  display: flex;
`;

export const MEDIA_QUERY_MAX = '(max-width: 1600px)';
export const MEDIA_QUERY_XXL = '(max-width: 1280px)';
export const MEDIA_QUERY_XL = '(max-width: 1024px)';
export const MEDIA_QUERY_LARGE = '(max-width: 900px)';
export const MEDIA_QUERY_MOBILE = '(max-width: 767px)';
export const MEDIA_QUERY_MOBILE_XS = '(max-width: 480px)';

export const columnStyles = (
  justify = 'flex-start',
  align = 'flex-start'
) => css`
  display: flex;
  flex-flow: column;
  justify-content: ${justify};
  align-items: ${align};
`;

export const rowStyles = (justify = 'flex-start', align = 'flex') => css`
  display: flex;
  flex-flow: row;
  justify-content: ${justify};
  align-items: ${align};
`;

type AdditionalTypographyProps = {
  format: 'light' | 'dark';
  weight?: number;
  children: ReactNode;
  id?: string;
  className?: string;
  variable?: boolean;
  ref?: ForwardedRef<HTMLHeadingElement>;
};

type TypographyProps<T> = Omit<
  DetailedHTMLProps<HTMLAttributes<T>, T>,
  'as' | 'css' | 'nonce' | 'onResize' | 'onResizeCapture' | 'ref'
> &
  AdditionalTypographyProps;

type HeadingProps = TypographyProps<HTMLHeadingElement>;
type ParagraphProps = TypographyProps<HTMLParagraphElement>;
type HeaderTextProps = HeadingProps & {
  type: 1 | 2 | 3 | 4 | 5 | 6;
};

const toVariantFromHeaderType = (type: HeaderTextProps['type']) => {
  switch (type) {
    case 1:
      return 'h1';
    case 2:
      return 'h2';
    case 3:
      return 'h3';
    case 4:
      return 'h4';
    case 5:
      return 'h5';
    case 6:
      return 'h6';
    default:
      return 'body1';
  }
};

export const HeaderText = forwardRef(function HeaderTextWithRef(
  { type, format, weight, variable, ...restOfProps }: HeaderTextProps,
) {
  return (
    <Typography
      color={format === 'light' ? 'info' : undefined}
      variant={toVariantFromHeaderType(type)}
      fontWeight={weight}
      {...restOfProps}
    />
  );
});

export const Paragraph = forwardRef(function ParagraphWithRef(
  {
    format,
    weight,
    variable,
    size = 'normal',
    ...restOfProps
  }: ParagraphProps & { size?: 'normal' | 'small' },
) {
  return (
    <Typography
      color={format === 'light' ? 'info' : undefined}
      fontWeight={weight}
      variant={size === 'normal' ? 'body1' : 'body2'}
      {...restOfProps}
    />
  );
});

export type Theme = {
  primary: string;
  secondary: string;
  tertiary: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  tertiaryButtonText: string;
  links: string;
  warning: string;
  success: string;
  light: string;
  dark: string;
  accent: string;
  black: string;
  white: string;
  grey: string;
  [x: string]: string;
};
export type Format = keyof Theme;

type LegacyButtonProps = Omit<HTMLProps<HTMLButtonElement>, 'as' | 'size'> & {
  $transparent?: boolean;
  fab?: boolean;
  pill?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  children?: ReactNode;
  format: Format;
  withBorder?: boolean;
  uppercase?: boolean;
  size?: ButtonSize;
  icon?: ReactNode;
  type?: 'submit' | 'reset' | 'button';
  label?: string;
  ref?: Ref<HTMLButtonElement | null>;
  as?: React.ElementType;
  variant?: ButtonProps['variant'];
};

type ButtonSize = 'small' | 'medium' | 'large';

const toMuiColorFromFormat = (format: Format): ButtonProps['color'] => {
  switch (format) {
    case 'primary':
      return 'primary';
    case 'secondary':
      return 'secondary';
    case 'tertiary':
    case 'warning':
      return 'warning';
    case 'light':
      return 'info';
    default:
      return 'primary';
  }
};

export const FabButton = ({
  $transparent,
  uppercase,
  format,
  fab,
  color,
  action,
  ...restOfProps
}: Omit<LegacyButtonProps, 'variant'>) => {
  return <Fab color={toMuiColorFromFormat(format)} {...restOfProps} />;
};

export const Button = ({
  $transparent,
  uppercase,
  format,
  fab,
  color,
  action,
  icon,
  ...restOfProps
}: LegacyButtonProps) => {
  return (
    <MuiButton
      color={toMuiColorFromFormat(format)}
      variant={$transparent ? 'outlined' : 'contained'}
      startIcon={icon}
      {...restOfProps}
      focusRipple
      focusVisibleClassName="focus-btn"
    >
      {restOfProps.children}
    </MuiButton>
  );
};
