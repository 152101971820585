'use client';

import { MEDIA_QUERY_LARGE, MEDIA_QUERY_XXL } from "#/lib/styles";
import { styled } from "@mui/material";

type Props = {
  format?: string;
  $contained?: boolean;
  $withPadding?: boolean;
};

const Container = styled('div')<Props>`
  max-width: ${props => (props.$contained ? '1280px' : 'none')};
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  ${props => (props.$withPadding ? 'padding: 50px 0' : '')};
  ${props => (props.format ? `background-color: ${props.format}` : '')}

  @media ${MEDIA_QUERY_XXL} {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }

  @media ${MEDIA_QUERY_LARGE} {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export default Container;
